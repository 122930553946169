#root {
  height: 100%;
}

.layout {
  min-height: 100%;
}

.menu-item {
  display: inline-flex;
  align-items: center;
  padding-left: 15px;
  cursor: pointer;
}

.ant-page-header {
  padding-top: 16px;
  padding-left: 0;
  padding-right: 20px;
}

.site-layout-header {
  background: #26242f;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

// TODO: remove me when token can differentiate here
.ant-menu-item-divider {
  border-top-width: 1px !important;
}

footer {
  background: #26242f !important;
  margin-top: 40px
}

.site-layout-content {
  min-height: 280px;
}

amplify-authenticator {
  background: url("background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100vh;
}

input[type='checkbox'] {
  cursor: pointer;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  margin: 50px 0;
}

.flex-center {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.text-centered {
  text-align: center;
}

a.strong {
  font-weight: bold;
}

.table-row-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
}

.icon-label {
  min-width: max-content;
  margin-right: 8px;
}

table.permissions {
  th, td { white-space: nowrap; padding: 5px 10px }
  td:not(:first-child) { text-align: center; }
}

// markdown

.wmde-markdown {
  background-color: transparent !important;
  font-family: 'Open Sans' !important;
}

/* google charts */

svg[aria-label="A chart."]>g>g:last-child {
  pointer-events: none
}